/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
import { BORDER_BOTTOM_STYLE } from '../constants/constants';

export const VerificationStyles = () => {
  const useClasses = makeStyles((theme) => ({
    tittleColor: {
      color: '#034694'
    },
    font16px: {
      fontSize: '16px'
    },
    boldFont: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(20),
    },
    boldFont1: {
      fontFamily: 'OpenSans-bold',
    },
    marginTop10p: {
      marginTop: '10%'
    },
    marginTop20p: {
      marginTop: '20%'
    },
    regularFont: {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
    font16px: {
      fontSize: '16px'
    },
    font14px: {
      fontSize: '14px'
    },
    font18px: {
      fontSize: '18px'
    },
    blackFont: {
      color: 'black'
    },
    mt20px: {
      marginTop: '20px'
    },
    p16px: {
      padding: '16px'
    },
    p10px: {
      padding: '10px'
    },
    pl16px: {
      paddingLeft: '16px'
    },
    pt10px: {
      paddingTop: '10px'
    },
    pl35px: {
      paddingLeft: '35px'
    },
    pb5px: {
      paddingBottom: '5px'
    },
    pb10px: {
      paddingBottom: '10px'
    },
    pt0px: {
      paddingTop: '0px'
    },
    textAlignLeft: {
      textAlign: 'left'
    },
    primaryButtonColor: {
      background: '#0f99d685',
      backgroundColor: '#0f99d6',
      '&:hover': {
        backgroundColor: '#0079af',
      },
      '&:active': {
        backgroundColor: '#0079af',
      },
      '&.Mui-disabled': {
        backgroundColor: '#0f99d685'
      }
    },
    primaryFontColor: {
      color: '#0f99d6'
    },
    width100p: {
      width: '100%'
    },
    centerAlign: {
      textAlign: 'center',
    },
    leftAlign: {
      textAlign: 'left',
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#000',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    mt2: {
      marginTop: theme.typography.pxToRem(10),
    },
    mt3: {
      marginTop: theme.typography.pxToRem(25),
    },
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },
    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mobileFrame: {
      paddingTop: '10%'
    },
    tabFrame: {
      padding: '20%'
    },
    desktopFrame: {
      padding: '7%',
      paddingTop: '4%'
    },
    tabGridStyle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    desktopGridStyle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    tabButtonSize: {
      width: '35%',
      marginTop: '5%'
    },
    desktopButtonSize: {
      width: '15%',
      marginTop: '2%'
    },
    iframeContainer: {
      position: 'relative',
      width: '100%', // Full width
      overflow: 'hidden',
    },
    iframeStyling: {
      width: '100%',
      height: '100%',
      border: 'none',
    },
    iframeMobile: {
      // Adjust based on your design
      width: '100%',
      height: '80vh', // Adjust for smaller screens
    },
    iframeTab: {
      width: '100%',
      height: '70vh', // Adjust for tablets
    },
    iframeDesktop: {
      width: '100%',
      height: '70vh', // Adjust for desktops
    },
    red: {
      backgroundColor: 'red'
    },
    heading: {
      paddingTop: '10px',
      width: '80%',
      fontFamily: 'OpenSans-bold',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '24.51px',
      textAlign: 'center',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: '#034694'
    },
    intellicheckTypeHeading: {
      paddingTop: '10px',
      fontFamily: 'OpenSans-bold',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '24.51px',
      textAlign: 'center',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: '#034694'
    },
    headingWidth: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    underline: {
      textDecoration: 'underline'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    mt10px: {
      marginTop: '10px'
    },
    drapNDropStyling: {
      padding: 2,
      border: '2px dashed #0f99d6',
      borderRadius: 1,
      textAlign: 'center',
      marginBottom: 2,
      boxShadow: 'none'
    },
    mb15px: { marginBottom: '15px' },
    mr10px: { marginRight: '10px' },
    sideHeading: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '21.79px',
      textAlign: 'center',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
    },
    gridOuterStyling: {
      border: '3px dashed #0f99d6',
    },
    maxWidth: {
      maxWidth: '697px'
    },
    minWidth: {
      minWidth: '697px'
    },
    buttonBorderColor: {
      border: '1px solid #0f99d6'
    },
    lightGreyColor: {
      color: '#A9ACB4'
    },
    w91p: {
      width: '95%'
    },
    mb20px: {
      marginBottom: '20px'
    },
    minWidth38p: {
      minWidth: '38%'
    },
    pl10px: {
      paddingLeft: '10px'
    },
    fileSizeColor: {
      color: '#707070'
    },
    buttonsPadding: {
      padding: '7px 20px'
    },
    p20px: {
      padding: '20px'
    },
    pl50px: {
      paddingLeft: '50px'
    },
    pl25px: {
      paddingLeft: '25px'
    },
    textAlignCenter: {
      textAlign: 'center'
    },
    otpInputClass: {
      '& input': {
        border: 'none', /* Remove the border */
        borderBottom: '1px solid #B9B9B9', /* Add bottom border */
        borderRadius: '0', /* Remove the border radius */
        textAlign: 'center', /* Center align the text */
        fontSize: '18px', /* Adjust font size */
        background: 'transparent',
        fontFamily: 'OpenSans-bold',
        transition: 'all 0.3s',
        marginTop: '10px',
        width: '25px'
      },
      '& input:hover': {
        backgroundColor: 'transparent', /* Ensure hover styling is neutral */
      },

      '& input:focus': {
        outline: 'none', /* Remove default browser outline */
        backgroundColor: 'transparent', /* Override hover background if applied */
        background: 'transparent',
        border: 'none',
      },

      '& input:not(:focus):hover': {
        backgroundColor: 'transparent', /* Disable hover effect when not focused */
      },
    },
    removeFocus: {
      '& .MuiInputBase-root': {
        '&.Mui-focused': {
          // Remove default focus border and shadow when focused
          boxShadow: 'none',
          borderBottom: '1px solid black', // Optional: customize border
        },
      }
    },
    otpInput: {
      width: "40px",
      "& .MuiInput-underline:before": {
        borderBottom: BORDER_BOTTOM_STYLE, // Bottom border styling
      },
      "& .MuiInput-underline:hover:before": {
        borderBottom: BORDER_BOTTOM_STYLE, // Hover effect for bottom border
      },
      "& .MuiInput-underline:after": {
        borderBottom: BORDER_BOTTOM_STYLE, // Focused border
      },
    },
    timerStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      marginBottom: '1rem', /* Adds space below the div */
    },
    timerDiv: {
      display: 'flex',
      alignItems: 'right',
    },
    lightGreyColor: {
      color: '#696969'
    },
    displayFlex: {
      display: 'flex'
    },
    spaceAround: {
      justifyContent: 'space-around'
    },
    errorMessageColor: {
      color: '#F72B2B',
      fontSize: '14px'
    },
    justifyContentCenter: {
      justifyContent: 'center'
    },
    successMessageColor: {
      color: '#0FF74D'
    },
    responsiveText: {
      display: 'block',
      lineHeight: 1.4,
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        whiteSpace: 'normal',
      },
    },
    responsiveFont14px: {
      fontSize: '14px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        width: '80%'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        width: '46%'
      },
    },
    responsiveFontTitle: {
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
      },
    },
    mt30px: {
      marginTop: '20px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '20px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '30px',
      },
    },
    mt100px: {
      marginTop: '100px',

    },
    flexDirectionColumn: {
      flexDirection: 'column'
    },
    responsiveText: {
      display: 'block',
      lineHeight: 1.4,
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        whiteSpace: 'normal',
      },
    },
    responsiveFontStyle: {
      fontSize: '14px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    listStyle: {
      listStyleType: "disc",
      margin: "0",
      paddingLeft: "20px",
      marginBottom: "19px",
      color: "#333",
    },
    startButtonWidth: {
      width: '80%',
      maxWidth: '200px'
    },
    openSansRegularFont: {
      fontFamily: 'OpenSans-regular'
    },
    listBottomStyle: { marginBottom: "12px", textAlign: "left", fontFamily: 'OpenSans-regular' },
    listBottomStyle2: { marginBottom: "8px", textAlign: "left", fontFamily: 'OpenSans-regular' },
    policyGridStyle: {
      fontSize: "0.8rem",
      textAlign: "center",
      marginTop: "30px",
      color: "#666",
      padding: '5px'
    },
    pl30px: {
      paddingLeft: '30px'
    },
    referenceContainer: {
      display: 'block',
      width: '75%',
      margin: '0 auto',
      textAlign: 'center'
    },
    referenceSection: {
      maxWidth: '535px',
      margin: '0 auto',
      textAlignCenter: 'center'
    },
    manulEntry: {
      color: '#0f99d6',
      textDecoration: 'underline',
      cursor: 'pointer'

    },
    residenceManulEntry: {
      width: '100%',
      padding: '3%',
      margin: '0 auto'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    maxWidth45p: {
      maxWidth: '45%'
    },
    responsiveWidth: {
      width: '49%',
      minWidth: '360px',
      margin: '0 auto'
    },
    pdfIconStyle: {
      width: "15px",
      height: "15px",
      [theme.breakpoints.down('xs')]: {
        marginTop: '2px',
        marginLeft: '2px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '2px',
        marginLeft: '5px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '6px',
        marginLeft: '6px',
      }
    },
    spaceBetween: {
      justifyContent: 'space-between'
    },
    marginRight10p: {
      marginRight: '10%'
    },
    pl5px: {
      paddingLeft: '5px'
    },
    fontSize11px: {
      fontSize: '11px'
    },
    adjustableMH: {
      marginTop: '10px',
      fontSize: '11px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '10px',
        fontSize: '11px'
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '8px',
        fontSize: '13px'
      }
    },
    alignItemsCenter: {
      alignItems: 'center'
    },
    checkedColor: {
      color: 'green',
      "&.Mui-checked": {
        color: 'green',
      }
    },
    w50p: {
      width: '70%',
    },
    ml55px: {
      marginLeft: '55px'
    },
    widthSize: {
      width: '80%',
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '80%'
      },
      [theme.breakpoints.up('md')]: {
        width: '80%',
        marginLeft: '-40px'
      }
    },
    customModal: {
      '& div': {
        '& div:nth-child(2)': {
          '& div': {
            maxWidth: '700px',
            maxHeight: '900px',
            height: '800px',
          }
        },
      },
    },
    racdropdownleftalign: {
      '& div ': {
        '& div': {
          textAlign: 'left'
        }
      }
    },
    p30px: {
      padding: '30px'
    },
    p6px0px: {
      padding: '6px 0px'
    },
    semiboldFont: {
      fontFamily: 'OpenSans-semibold',
    },
    ml10px: {
      marginLeft: '10px',
    },
    ml5px: {
      marginLeft: '5px',
    },
    mb20px: {
      marginBottom: '20px'
    },
    intellicheckIdType: {
      marginTop: '25px',
      '& div ': {
        '& div': {
          textAlign: 'left'
        }
      }
    },
    IDTypeLabelName: {
      marginBottom: '10px'
    }
  }))
  return useClasses()
}