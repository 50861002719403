/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
import { CustomerInfo, IntellicheckCustomerDetails } from './../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel';
import { CUSTOMER_INFO_TEMPLATE, INTELLICHECK_DETAILS_TEMPLATE } from './../../../constants/constants';
import { dropdown } from '../Common/HelperFunctions';
export const DigitalOrderFormContext = createContext<any>('');
export function DigitalOrderFormContextProvider(props: any) {
    const [customerDetails, setCVOCustomerDetails] = useState<CustomerInfo>(CUSTOMER_INFO_TEMPLATE);
    const [decisionEngineDetails, setDecisionEngineDetails] = useState<any>('');
    const [intellicheckVerfDetails, setIntellicheckVerfDetails] = useState<IntellicheckCustomerDetails>(INTELLICHECK_DETAILS_TEMPLATE);
    const [businessFlow, setBusinessFlow] = useState<string>('DOF');
    const [racpadCustomerDetail, setRacPadCustomerDetail] = useState<any>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [identityFrontDoc, setIdentityFrontDoc] = useState<string>('');
    const [identitySelfieDoc, setIdentitySelfieDoc] = useState<string>('');
    const [incomeDoc, setIncomeDoc] = useState<string>('');
    const [incomeDocCopy, setIncomeDocCopy] = useState<string>('');
    const [residenceDoc, setResidenceDoc] = useState<string>('');
    const [showIdentityDetails, setShowIdentityDetails] = useState<boolean>(false);
    const [showIncomeDetails, setShowIncomeDetails] = useState<boolean>(false);
    const [showReferenceDetails, setShowReferenceDetails] = useState<boolean>(false);
    const [showResidenceDetails, setShowResidenceDetails] = useState<boolean>(false);
    const [showIncomeVerfDocument, setShowIncomeVerfDocument] = useState<boolean>(false);
    const [showResidenceVerfDocument, setShowResidenceVerfDocument] = useState<boolean>(false);
    const [incomeFileSize, setIncomeFileSize] = useState<string>('');
    const [incomeFileType, setIncomeFileType] = useState<string>('');
    const [existingFlow, setexistingFlow] = useState<boolean>(false);
    const [dynamicHeader, setDynamicHeader] = useState<any>([]);
    const [isIdentityDetailsFetched, setIsIdentityDetailsFetched] = useState<boolean>(false);
    const [intellicheckPassScore, setIntellicheckPassscore] = useState<any>('0');
    const [ecomSubmission, setEcomSubmission] = useState(false);
    const [navHeader, setNavHeader] = useState<any>(null);
    const [custMobNumber, setCustMobNumber] = useState<any>('');
    const [incomeFileTypeCopy, setIncomeFileTypeCopy] = useState<string>("")
    const [incomeFileSizeCopy, setIncomeFileSizeCopy] = useState<string>('')
    const [intellicheckIdTypes, setIntellicheckIdTypes] = useState<dropdown[]>([]);
    const [intellicheckIdType, setIntellicheckIdType] = useState<string>('')



    return (
        <div>
            <DigitalOrderFormContext.Provider
                value={{
                    customerDetails, setCVOCustomerDetails,
                    decisionEngineDetails, setDecisionEngineDetails,
                    intellicheckVerfDetails, setIntellicheckVerfDetails,
                    businessFlow, setBusinessFlow,
                    racpadCustomerDetail, setRacPadCustomerDetail,
                    customerId, setCustomerId,
                    identityFrontDoc, setIdentityFrontDoc,
                    identitySelfieDoc, setIdentitySelfieDoc,
                    incomeDoc, setIncomeDoc,
                    residenceDoc, setResidenceDoc,
                    showResidenceDetails, setShowResidenceDetails,
                    showIdentityDetails, setShowIdentityDetails,
                    showIncomeDetails, setShowIncomeDetails,
                    showReferenceDetails, setShowReferenceDetails,
                    showIncomeVerfDocument, setShowIncomeVerfDocument,
                    showResidenceVerfDocument, setShowResidenceVerfDocument,
                    incomeFileSize, setIncomeFileSize,
                    incomeFileType, setIncomeFileType,
                    existingFlow, setexistingFlow,
                    dynamicHeader, setDynamicHeader,
                    isIdentityDetailsFetched, setIsIdentityDetailsFetched,
                    intellicheckPassScore, setIntellicheckPassscore,
                    incomeDocCopy, setIncomeDocCopy,
                    ecomSubmission, setEcomSubmission,
                    navHeader, setNavHeader,
                    custMobNumber, setCustMobNumber,
                    incomeFileTypeCopy, setIncomeFileTypeCopy,
                    incomeFileSizeCopy, setIncomeFileSizeCopy,
                    intellicheckIdTypes, setIntellicheckIdTypes,
                    intellicheckIdType, setIntellicheckIdType
                }}
            >
                {props.children}
            </DigitalOrderFormContext.Provider>
        </div>
    );
}
