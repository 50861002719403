/* eslint-disable */
import React, { createContext, useState } from 'react';
import {
  EmployerDetails,
  ReferenceDetails,
  ResidenceDetails,
  VerifyInputValues,
  customerDetails,
  dropdown,
} from '../interfaces/interfaces';
export const dofContextValue = createContext<any>('');
export function DofContextProvider(props: any) {
  const [noDeReasonOptions, setNoDeReasonOptions] = useState<dropdown[]>([]);
  const [stateOptions, setStateOptions] = useState<dropdown[]>([]);
  const [bestTimeToCallOptions, setBestTimeToCallOptions] = useState<any[]>([]);
  const [formatedLLPhone, setFormatedLLPhone] = useState<string>('');

  const [relationshipOptions, setRelationshipOptions] = useState<dropdown[]>(
    []
  );
  const [govtIdTypeOptions, setGovtIdTypeOptions] = useState<dropdown[]>([]);
  const [paydayFrequenchOptions, setPaydayFrequenchOptions] = useState<
    dropdown[]
  >([]);
  const [dayPaidOption1, setDayPaidOption1] = useState<dropdown[]>([]);
  const [dayPaidOption2, setDayPaidOption2] = useState<dropdown[]>([]);
  const [dayPaidOption3, setDayPaidOption3] = useState<dropdown[]>([]);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)

  const [isPrevious, setIsPrevious] = useState<boolean>(false);
  const [formatMobileNumber, setFormatMobileNumber] = useState<string>("");
  const [formatedMobileNumber, setFormatedMobileNumber] = useState('')
  const [isDOBVerified, setIsDOBVerified] = useState<boolean>(false);
  const [isAddressLine1Verified, setIsAddressLine1Verified] =
    useState<boolean>(false);
  const [isZIPCodeVerified, setIsZipCodeVerified] = useState<boolean>(false);
  const [isCityVerified, setIsCityVerified] = useState<boolean>(false);
  const [isStateVerified, setIsStateVerified] = useState<boolean>(false);
  const [isDOBDisable, setIsDOBDisable] = useState<boolean>(false);



  const [dayPaidOption, setDayPaidOption] = useState<dropdown[]>([{ value: '', label: '' }])

  const [isVerified, setIsVerified] = useState<VerifyInputValues>({
    basicCallTime: false,
    govtIdType: false,
    govtId: false,
    govtIdState: false,

    dayPaidDisabled: false,
    zipCodeEmployer: true,
    companyName: false,
    employerPhone: false,
    PayDayFrequency: false,
    dayPaid: false,

    landLordFirstName: false,
    landLordLastName: false,
    landLordPhoneNumber: false,
    mortgageCompany: false,

    refFirstName: false,
    refLastName: false,
    refPhoneNumber: false,
    refRelation: false,
    refZipOptional: true,
  });

  const [customerDetails, setCustomerDetails] = useState<customerDetails>({
    customerId: '',
    globalCustomerId: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    mobileNumber: '',
    emailAddress: '',
    fullSsn: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    selectedState: '',
    approvalResponse: '',
    totalApprovalAmount: '',
    weeklyApprovalAmount: '',
    unFormattedPhoneNumber: '',
    bestTimeToCall: '',
    govtIdType: '',
    govtId: '',
    govtIdState: '',
    deExceptionReason: '',
    storeCode: '',
    isSsnDisabled: false,
    authKey: '',
    lastFourSsn: '',
    FCRAConsentEnabled: false

  });

  const [employerDetails, setEmployerDetails] = useState<EmployerDetails>({
    companyName: '',
    howLong: '',
    employerPhone: '',
    paydayFrequency: '',
    dayPaid: '',
    takeHomePay: '',
    empAddress1: '',
    empAddress2: '',
    empZipCode: '',
    empCity: '',
    empState: '',
  });

  const [residenceDetails, setResidenceDetails] = useState<ResidenceDetails>({
    residenceInfo: '',
    landlordFirstName: '',
    landlordLastName: '',
    landlordPhoneNumber: '',
    rentMoveInDate: '',
    ownMoveInDate: '',
    isMortgage: false,
    rentPayment: '',
    ownMortgagePayment: '',
    mortgageCompanyName: '',
  });

  const [referenceDetails, setReferenceDetails] = useState<ReferenceDetails>({
    refFirstName: '',
    refLastName: '',
    refPhoneNumber: '',
    refBestTimeToCall: '',
    relationShipType: '',
    refAddress1: '',
    refAddress2: '',
    refZipCode: '',
    refCity: '',
    refState: '',
  });

  const [TCPASelected, setTCPASelected] = useState(false)
  let [phoneSolicitationAllowed, setphoneSolicitationAllowed] = useState(false)

  return (
    <div>
      <dofContextValue.Provider
        value={{
          customerDetails,
          setCustomerDetails,
          referenceDetails,
          setReferenceDetails,
          employerDetails,
          setEmployerDetails,
          residenceDetails,
          setResidenceDetails,
          noDeReasonOptions,
          setNoDeReasonOptions,
          stateOptions,
          setStateOptions,
          bestTimeToCallOptions,
          setBestTimeToCallOptions,
          relationshipOptions,
          setRelationshipOptions,
          govtIdTypeOptions,
          setGovtIdTypeOptions,
          formatedLLPhone,
          dayPaidOption,
          setDayPaidOption,
          setFormatedLLPhone,
          paydayFrequenchOptions,
          setPaydayFrequenchOptions,
          dayPaidOption1,
          setDayPaidOption1,
          formatMobileNumber, setFormatMobileNumber,
          formatedMobileNumber, setFormatedMobileNumber,
          dayPaidOption2,
          setDayPaidOption2,
          dayPaidOption3,
          setDayPaidOption3,
          isPrevious,
          setIsPrevious,
          isVerified,
          setIsVerified,
          isEmailVerified,
          setIsEmailVerified,
          isDOBVerified, setIsDOBVerified,
          isAddressLine1Verified, setIsAddressLine1Verified,
          isZIPCodeVerified, setIsZipCodeVerified,
          isCityVerified, setIsCityVerified,
          isStateVerified, setIsStateVerified,
          isDOBDisable, setIsDOBDisable,
          TCPASelected, setTCPASelected,
          phoneSolicitationAllowed, setphoneSolicitationAllowed

        }}
      >
        {props.children}
      </dofContextValue.Provider>
    </div>
  );
}
