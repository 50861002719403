/* eslint-disable */
import { Grid, RACButton, RACSelect, Typography } from '@rentacenter/racstrap';
import React, { useContext, useEffect } from 'react';
import { VerificationStyles } from '../../../styles/verificationStyles';
import doflogo from '../../../images/logo.svg';
import { useHistory, useParams } from 'react-router-dom';
import { DigitalOrderFormContext } from '../Context/DigitialOrderFormContext';

export const IntellicheckIdType = () => {
    const classes: any = VerificationStyles();
    const dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
    const { intellicheckIdTypes, intellicheckIdType, setIntellicheckIdType } = useContext(DigitalOrderFormContext)
    const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
    const clientId: any = sessionStorageValues?.clientId
    const { approvalId } = useParams<any>();
    const history = useHistory();


    useEffect(() => {

        // Call sendHeightToParent initially to send the height on mount
        sendHeightToParent();
        // Add an event listener for window resizing
        const handleResize = () => {
            sendHeightToParent();
        };
        window.addEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    // Adding the Google Analytics tags
    useEffect(() => {
        window.dataLayer?.push({
            'event': 'Loaded the DOF OTP Verified Page',
            'storeNo': sessionStorageValues?.storeNumber,
            'approvalId': sessionStorageValues?.approvalId,
            'currentScreen': 'DOF OTP Verified Screen',
            'previousScreen': 'DOF OTP Authentication Screen',
        });
    }, [])

    const sendHeightToParent = () => {
        const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
        const iframeWidth = document.body.scrollWidth;
        window.parent.postMessage(
            {
                message: "racpad-styling",
                minHeight: iframeHeight < 540 ? 540 : iframeHeight,
                currentHeight: iframeHeight,
                iframeWidth: iframeWidth,
                verificationProcessCompleted: false
            },
            "*"
        );
    };
    const NextBtnClick = async () => {
        history.push(`/dof/verification/${approvalId}`)
    }
    return (
        <Grid>
            <Grid className={`${classes.tabGridStyle} ${classes.mt10px}`}>

                <Grid style={{ marginTop: "7%", minWidth: '400px' }} className={classes.p16px}>
                    {(atob(clientId) == "EC" || atob(clientId) == 'ECOM') ? null : (
                        <Grid style={{ textAlign: "center", display: "inline-flex" }}>
                            <img
                                src={doflogo}
                                style={{
                                    float: "left",
                                    verticalAlign: "middle",
                                    padding: "16px 24px",
                                }}
                            />
                        </Grid>
                    )}
                    <Grid className={classes.headingWidth}>
                        <Typography className={`${classes.intellicheckTypeHeading}`}>{`Please choose your ID type to verify`}</Typography>
                    </Grid>
                    <div className={classes.intellicheckIdType}>
                        <RACSelect
                            inputLabel={"ID Type"}
                            inputLabelClassName={classes.IDTypeLabelName}
                            required={true}
                            options={intellicheckIdTypes}
                            onChange={(e: any) => { setIntellicheckIdType(e.target.value) }}
                            defaultValue={intellicheckIdType}
                        />
                    </div>

                    <RACButton
                        color="primary"
                        variant="contained"
                        className={`${classes.primaryButtonColor} ${classes.buttonsPadding} ${classes.mt100px}`}
                        disabled={intellicheckIdType ? false : true}
                        onClick={() => {
                            NextBtnClick()
                        }}
                    >
                        Next
                    </RACButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
