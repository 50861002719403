/* eslint-disable */
import { Grid, RACButton, Typography } from '@rentacenter/racstrap';
import React, { useContext, useEffect } from 'react';
import { VerificationStyles } from '../../../styles/verificationStyles';
// import { useHistory, useParams } from 'react-router-dom';
import doflogo from '../../../images/logo.svg';
import { ReactComponent as TickIcon } from './../../../images/tick-icon.svg';
import { useHistory, useParams } from 'react-router-dom';
import { DigitalOrderFormContext } from '../Context/DigitialOrderFormContext';
import { getPendingPriorityDocumentName } from '../Common/HelperFunctions';

export const OTPVerified = () => {
  const classes: any = VerificationStyles();
  const dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  const { decisionEngineDetails, customerId, setCustomerId } = useContext(DigitalOrderFormContext)
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  const clientId: any = sessionStorageValues?.clientId
  const { approvalId } = useParams<any>();
  const history = useHistory();

  useEffect(() => {

    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCustomerId(customerId)
  }, [customerId])

  // // Adding the Google Analytics tags
  useEffect(() => {
    window.dataLayer?.push({
      'event': 'Loaded the DOF OTP Verified Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF OTP Verified Screen',
      'previousScreen': 'DOF OTP Authentication Screen',
    });
  }, [])

  const sendHeightToParent = () => {
    const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
    const iframeWidth = document.body.scrollWidth;
    window.parent.postMessage(
      {
        message: "racpad-styling",
        minHeight: iframeHeight < 540 ? 540 : iframeHeight,
        currentHeight: iframeHeight,
        iframeWidth: iframeWidth,
        verificationProcessCompleted: false
      },
      "*"
    );
  };
  const NextBtnClick = async () => {
    if (!approvalId || !customerId) {
      history.push('/userinfo')
    }
    else {
      if (decisionEngineDetails) {
        const dynamicrouteRes: any = await getPendingPriorityDocumentName(decisionEngineDetails)
        if (dynamicrouteRes) {
          if (dynamicrouteRes == 'identity') {
            history.push(`/dof/intellicheck/id/type/${approvalId}`)
          }
          else {
            history.push(`/${dynamicrouteRes}/${customerId}/${approvalId}`)
          }
        }
        else {
          history.push(`/dof/submission/${customerId}/${approvalId}`);
        }
      }
      else {
        history.push(`/dof/intellicheck/id/type/${approvalId}`)
      }
    }
  }
  return (
    <Grid style={{ width: "100%", height: "80vh" }}>
      <Grid style={{ marginTop: "7%" }}>
        {(atob(clientId) == "EC" || atob(clientId) == 'ECOM') ? null : (
          <Grid style={{ textAlign: "center", display: "inline-flex" }}>
            <img
              src={doflogo}
              style={{
                float: "left",
                verticalAlign: "middle",
                padding: "16px 24px",
              }}
            />
          </Grid>
        )}

        <Typography
          className={`${classes.primaryFontColor} ${classes.responsiveFontTitle} ${classes.boldFont1} ${classes.p10px}`}
        >
          Phone Number Verified
        </Typography>

        <Grid>
          <Typography
            className={`${classes.responsiveFont14px} ${classes.p10px} ${classes.responsiveText} ${classes.p30px}`}
          >
            Your phone number has been verified successully.
          </Typography>
        </Grid>

        <Grid className={`${classes.headingWidth} ${classes.mt30px}`}>
          <TickIcon />
        </Grid>

        <RACButton
          color="primary"
          variant="contained"
          className={`${classes.primaryButtonColor} ${classes.buttonsPadding} ${classes.mt30px}`}
          onClick={() => {

            NextBtnClick()

          }}
        >
          Next
        </RACButton>
      </Grid>
    </Grid>
  );
};
