/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  Grid,
  RACButton,
  Card,
  Typography,
  RACCheckBox,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { dofContextValue } from '../context/DofContext';
import { DofCssClasses } from '../styles/dofStyles';
import DofHeaders from './DofHeaders';
import { useHistory } from 'react-router-dom';
import {
  EmployerReference,
  LandlordReference,
  PersonalReference,
  UpdateAddress,
  UpdateCustomerRequest,
  UpdatePhone,
} from '../interfaces/updateCustomer';
import { dropdown } from '../interfaces/interfaces';
import { howLongOptions } from '../constants/constants';
import { UpdateCustomerInfo, updateAccessLink } from '../api/user';
import { LoaderPopup } from './LoderPopup';
import moment from 'moment';
export default function ReviewInfo() {
  const history = useHistory();
  const {
    customerDetails,
    employerDetails,
    residenceDetails,
    referenceDetails,
    bestTimeToCallOptions,
    govtIdTypeOptions,
    paydayFrequenchOptions,
    dayPaidOption1,
    dayPaidOption2,
    dayPaidOption3,
    relationshipOptions,
    phoneSolicitationAllowed
  } = useContext(dofContextValue);

  const dofClassName: any = DofCssClasses();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verifyBox1, setVerifyBox1] = useState<boolean>(false);
  const [verifyBox2, setVerifyBox2] = useState<boolean>(false);

  const handleChange = (e: any) => {
    if (e.target.name === 'check_box_1') {
      if (verifyBox1) {
        setVerifyBox1(false);
      } else {
        setVerifyBox1(true);
      }
    } else if (e.target.name === 'check_box_2') {
      if (verifyBox2) {
        setVerifyBox2(false);
      } else {
        setVerifyBox2(true);
      }
    }
  };

  // Function for previous button click

  function handleExisCustPreviousBtn() {
    if (customerDetails?.approvalResponse == '1') {
      history.push('/employer');
    }
    else {
      history.push('/reference');
    }
  }
  // Function for  cotinue button click

  async function handleContinueBtn() {
    setIsLoading(true);
    console.log('Inside Payload formation', customerDetails);
    console.log('Employment Details', employerDetails);
    console.log('reference Details', residenceDetails);

    const address: UpdateAddress = {
      addressLine1: customerDetails.addressLine1,
      addressLine2: customerDetails.addressLine2,
      city: customerDetails.city,
      state: customerDetails.selectedState,
      postalCode: customerDetails.zipCode,
      addressType: 'PRIM',
    };

    const phone: UpdatePhone = {
      phoneNumber: customerDetails.unFormattedPhoneNumber,
      phoneType: 'CELL',
      callTimeType: customerDetails.bestTimeToCall,
    };

    const employerReferences: EmployerReference = {
      employerName: employerDetails.companyName,
      employerPhoneNumber: employerDetails.employerPhone?.replace(/[()\s-]/g, ''),
      employerPayschedule: employerDetails.paydayFrequency,
      daysPaid: employerDetails.dayPaid ? employerDetails.dayPaid : null,
      takeHomePay: employerDetails.takeHomePay
        ? employerDetails.takeHomePay.replace(',', '').toString()
        : '0.00',
      addressLine1: employerDetails.empAddress1
        ? employerDetails.empAddress1
        : null,
      addressLine2: employerDetails.empAddress2
        ? employerDetails.empAddress2
        : null,
      city: employerDetails.empCity ? employerDetails.empCity : null,
      state: employerDetails.empState ? employerDetails.empState : null,
      postalCode: employerDetails.empZipCode
        ? employerDetails.empZipCode
        : null,
      lengthOfEmploymentYears: employerDetails.howLong
        ? employerDetails.howLong
        : null,
      employmentBeginDate: moment().format('YYYY-MM-DD')
    };

    const landlordReferences: LandlordReference = {
      landlordFirstName:
        residenceDetails.residenceInfo === 'Rent'
          ? residenceDetails.landlordFirstName
          : null,
      landlordLastName:
        residenceDetails.residenceInfo === 'Rent'
          ? residenceDetails.landlordLastName
          : null,
      phoneNumber:
        residenceDetails.residenceInfo === 'Rent'
          ? residenceDetails.landlordPhoneNumber?.replace(/[()\s-]/g, '')
          : null,
      monthlyRent:
        residenceDetails.rentPayment &&
          residenceDetails.residenceInfo === 'Rent'
          ? residenceDetails.rentPayment.replace(',', '').toString()
          : '0.00',
      moveInDate:
        residenceDetails.rentMoveInDate &&
          residenceDetails.residenceInfo === 'Rent'
          ? residenceDetails.rentMoveInDate
          : null,
      active: 'Y',
      leaseLengthYears: "0.000000",
      leaseLengthMonths: "0.000000",
      leaseTerm: null,
      leaseCompanyName: null,
      phoneExtension: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      postalCode: "",
      leaseHolderName: null,
      bestTimeToCall: null,
      verifiedDate: ""
    };

    const personalReferences: PersonalReference = {
      firstName: referenceDetails.refFirstName,
      lastName: referenceDetails.refLastName,
      phoneNumber: referenceDetails.refPhoneNumber?.replace(/[()\s-]/g, ''),
      bestTimeToCall: referenceDetails.refBestTimeToCall
        ? referenceDetails.refBestTimeToCall
        : null,
      relationshipType: referenceDetails.relationShipType,
      addressLine1: referenceDetails.refAddress1
        ? referenceDetails.refAddress1
        : null,
      addressLine2: referenceDetails.refAddress2
        ? referenceDetails.refAddress2
        : null,
      postalCode: referenceDetails.refZipCode
        ? referenceDetails.refZipCode
        : null,
      city: referenceDetails.refCity ? referenceDetails.refCity : null,
      state: referenceDetails.refState ? referenceDetails.refState : null,
    };

    const updateCustomerPayload: UpdateCustomerRequest = {
      customerId: customerDetails.customerId,
      globalCustomerId: customerDetails.globalCustomerId,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      dateOfBirth: customerDetails.dateOfBirth,
      taxId: customerDetails.fullSsn
        ? customerDetails.fullSsn
        : null,
      taxIdType: customerDetails.fullSsn
        ? 'SSN'
        : null,
      governmentId: customerDetails.govtId,
      governmentIdType: customerDetails.govtIdType,
      governmentIdIssuedState: customerDetails.govtIdState,
      emailAddress: customerDetails.email,
      decisionStatus: customerDetails.approvalResponse
        ? customerDetails.approvalResponse
        : null,
      deExceptionReason: customerDetails.deExceptionReason
        ? customerDetails.deExceptionReason
        : null,

      mortgageCompanyName:
        residenceDetails.residenceInfo === 'Own' &&
          residenceDetails.isMortgage &&
          residenceDetails.mortgageCompanyName
          ? residenceDetails.mortgageCompanyName
          : null,
      monthlyMortgagePayment:
        residenceDetails.residenceInfo === 'Own' &&
          residenceDetails.isMortgage &&
          residenceDetails.ownMortgagePayment
          ? residenceDetails.ownMortgagePayment.replace(',', '').toString()
          : null,
      residenceSince:
        residenceDetails.residenceInfo === 'Own' &&
          residenceDetails.ownMoveInDate
          ? residenceDetails.ownMoveInDate
          : null,
      residenceType: residenceDetails.residenceInfo == 'Rent' ? 'RENT' : 'OWN',
      phoneSolicitationAllowed: phoneSolicitationAllowed == true ? 'Y' : 'N',
      textSolicitationAllowed: phoneSolicitationAllowed == true ? 'Y' : 'N',
      phones: [phone],
      addresses: [address],
      employerReferences: [employerReferences],
      ...(residenceDetails.residenceInfo == 'Rent' && { landlordReferences: [landlordReferences] }),
      ...(personalReferences?.firstName && personalReferences?.lastName) && {
        personalReferences: [personalReferences],
      }
    };

    console.log('Final Payload', updateCustomerPayload);

    const updateResponse = await UpdateCustomerInfo(updateCustomerPayload, customerDetails.authKey ? false : customerDetails.storeCode, customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode);

    console.log('Update API Response', updateResponse);

    if (
      updateResponse &&
      updateResponse.UpdateCustomer &&
      updateResponse.UpdateCustomer.value &&
      updateResponse.UpdateCustomer.value.customerId
    ) {
      const payload: any = {};
      payload.phoneNumber = customerDetails?.authFlowPhone ? customerDetails?.unFormattedPhoneNumber : !customerDetails?.authFlowEmail ? customerDetails.unFormattedPhoneNumber : '';
      payload.emailAddress = customerDetails?.authFlowEmail ? customerDetails?.email : !customerDetails?.authFlowPhone ? customerDetails?.email : '';
      payload.token = customerDetails?.authKey?customerDetails?.authKey:customerDetails?.storeFlowAuthKey;

      await updateAccessLink(payload, customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode);
      setIsLoading(false);
      history.push('/ApprovalPage');
    } else {
      setIsLoading(false);
      history.push('/IdentityVerify?status=Exception');
    }
  }

  const findLabel = (dropOptions: dropdown[], value: string) => {
    // const actual
    let actualLabel = '';
    dropOptions.forEach((obj: dropdown) => {
      if (obj.value === value) {
        actualLabel = obj.label;
      }
    });
    return actualLabel;
  };

  return (
    <Grid>
      <DofHeaders />
      {!isLoading ? (
        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.floatCenter}`}
          >
            <Grid
              item
              lg={6}
              md={8}
              sm={10}
              xs={11}
              className={`${dofClassName.marginLeftRight}`}
            >
              <Typography
                className={`${dofClassName.existCustTitle} ${dofClassName.blueColor} ${dofClassName.fontSize23px}`}
              >
                {' '}
                Review Your Information{' '}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.floatCenter}`}
          >
            <Grid container lg={8} md={8} sm={10} xs={11}>
              <Card className={`${dofClassName.cardContainer}`}>
                <Grid item className={`${dofClassName.basicInfoCardStyle}`}>
                  <Typography className={`${dofClassName.headingForReview}`}>
                    Basic Information
                  </Typography>
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      Mobile:{' '}
                    </span>
                    {customerDetails.mobileNumber}
                  </Typography>
                  {customerDetails.bestTimeToCall ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        Best Time to Call:{' '}
                      </span>
                      {findLabel(
                        bestTimeToCallOptions,
                        customerDetails.bestTimeToCall
                      )}
                    </Typography>
                  ) : (
                    ''
                  )}
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      {' '}
                      Govt. ID Type:{' '}
                    </span>
                    {findLabel(govtIdTypeOptions, customerDetails.govtIdType)}
                  </Typography>
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      {' '}
                      Govt. ID #:{' '}
                    </span>
                    {customerDetails.govtId}
                  </Typography>
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      {' '}
                      Govt. ID State:{' '}
                    </span>
                    {customerDetails.govtIdState}
                  </Typography>

                  <Typography className={`${dofClassName.headingForReview}`}>
                    Employment Information
                  </Typography>
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      Company Name:{' '}
                    </span>
                    {employerDetails.companyName}
                  </Typography>

                  {employerDetails.howLong ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        How Long?:{' '}
                      </span>
                      {findLabel(howLongOptions, employerDetails.howLong)}
                    </Typography>
                  ) : (
                    ''
                  )}

                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      {' '}
                      Employer Phone #:
                    </span>
                    {employerDetails.employerPhone}
                  </Typography>
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      {' '}
                      Payday Frequency:{' '}
                    </span>
                    {findLabel(
                      paydayFrequenchOptions,
                      employerDetails.paydayFrequency
                    )}
                  </Typography>
                  <Typography className={`${dofClassName.marginTop8px}`}>
                    <span className={`${dofClassName.spanClass}`}>
                      {' '}
                      Day Paid:{' '}
                    </span>
                    {employerDetails.dayPaid.length === 3
                      ? findLabel(dayPaidOption1, employerDetails.dayPaid)
                      : employerDetails.dayPaid.length === 1 ||
                        employerDetails.dayPaid.length === 2
                        ? findLabel(dayPaidOption2, employerDetails.dayPaid)
                        : employerDetails.dayPaid.length > 3
                          ? findLabel(dayPaidOption3, employerDetails.dayPaid)
                          : ''}
                    {employerDetails.dayPaid}
                  </Typography>

                  {employerDetails.takeHomePay &&
                    employerDetails.takeHomePay !== '0.00' ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        {' '}
                        Take Home Pay:{' '}
                      </span>
                      {employerDetails.takeHomePay}
                    </Typography>
                  ) : (
                    ''
                  )}

                  {employerDetails.empAddress1 ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        {' '}
                        Address Line 1:{' '}
                      </span>
                      {employerDetails.empAddress1}
                    </Typography>
                  ) : (
                    ''
                  )}

                  {employerDetails.empAddress2 ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        {' '}
                        Address Line 2:{' '}
                      </span>
                      {employerDetails.empAddress2}
                    </Typography>
                  ) : (
                    ''
                  )}

                  {employerDetails.empCity ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        {' '}
                        City:{' '}
                      </span>
                      {employerDetails.empCity}
                    </Typography>
                  ) : (
                    ''
                  )}

                  {employerDetails.empState ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        {' '}
                        State:{' '}
                      </span>
                      {employerDetails.empState}
                    </Typography>
                  ) : (
                    ''
                  )}

                  {employerDetails.empZipCode ? (
                    <Typography className={`${dofClassName.marginTop8px}`}>
                      <span className={`${dofClassName.spanClass}`}>
                        {' '}
                        ZIP Code:{' '}
                      </span>
                      {employerDetails.empZipCode}
                    </Typography>
                  ) : (
                    ''
                  )}

                  {customerDetails?.approvalResponse != '1' ? <>

                    {residenceDetails.residenceInfo ? (
                      <Grid>
                        <Typography
                          className={`${dofClassName.headingForReview}`}
                        >
                          Residence Information
                        </Typography>

                        {residenceDetails.residenceInfo === 'Rent' ? (
                          <Grid>
                            <Typography
                              className={`${dofClassName.marginTop8px}`}
                            >
                              <span className={`${dofClassName.spanClass}`}>
                                {' '}
                                Landlord’s First Name:{' '}
                              </span>
                              {residenceDetails.landlordFirstName}
                            </Typography>

                            <Typography
                              className={`${dofClassName.marginTop8px}`}
                            >
                              <span className={`${dofClassName.spanClass}`}>
                                {' '}
                                Landlord’s Last Name:{' '}
                              </span>
                              {residenceDetails.landlordLastName}
                            </Typography>

                            <Typography
                              className={`${dofClassName.marginTop8px}`}
                            >
                              <span className={`${dofClassName.spanClass}`}>
                                {' '}
                                Landlord’s Phone Number:{' '}
                              </span>
                              {residenceDetails.landlordPhoneNumber}
                            </Typography>
                            {residenceDetails.rentMoveInDate ? (
                              <Typography
                                className={`${dofClassName.marginTop8px}`}
                              >
                                <span className={`${dofClassName.spanClass}`}>
                                  {' '}
                                  Move In Date{' '}
                                </span>
                                {residenceDetails.rentMoveInDate}
                              </Typography>
                            ) : (
                              ''
                            )}

                            {residenceDetails.rentPayment ? (
                              <Typography
                                className={`${dofClassName.marginTop8px}`}
                              >
                                <span className={`${dofClassName.spanClass}`}>
                                  Payment Amount
                                </span>
                                {residenceDetails.rentPayment}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Grid>
                        ) : (
                          ''
                        )}

                        {residenceDetails.residenceInfo === 'Own' ? (
                          <Grid>
                            {residenceDetails.isMortgage ? (
                              <Grid>
                                <Typography
                                  className={`${dofClassName.marginTop8px}`}
                                >
                                  <span className={`${dofClassName.spanClass}`}>
                                    {' '}
                                    Mortgage:{' '}
                                  </span>
                                  {'Yes'}
                                </Typography>

                                <Typography
                                  className={`${dofClassName.marginTop8px}`}
                                >
                                  <span className={`${dofClassName.spanClass}`}>
                                    {' '}
                                    Mortgage Company Name:{' '}
                                  </span>
                                  {residenceDetails.mortgageCompanyName}
                                </Typography>

                                <Typography
                                  className={`${dofClassName.marginTop8px}`}
                                >
                                  <span className={`${dofClassName.spanClass}`}>
                                    {' '}
                                    Payment Amount:{' '}
                                  </span>
                                  {residenceDetails.ownMortgagePayment}
                                </Typography>
                              </Grid>
                            ) : (
                              ''
                            )}

                            <Typography
                              className={`${dofClassName.marginTop8px}`}
                            >
                              <span className={`${dofClassName.spanClass}`}>
                                {' '}
                                Move In Date{' '}
                              </span>
                              {residenceDetails.ownMoveInDate}
                            </Typography>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    ) : (
                      ''
                    )}

                    {referenceDetails.refFirstName ? <Grid>
                      <Typography className={`${dofClassName.headingForReview}`}>
                        Reference Information
                      </Typography>
                      <Typography className={`${dofClassName.marginTop8px}`}>
                        <span className={`${dofClassName.spanClass}`}>
                          Parent / Relative First Name:{' '}
                        </span>
                        {referenceDetails.refFirstName}
                      </Typography>
                      <Typography className={`${dofClassName.marginTop8px}`}>
                        <span className={`${dofClassName.spanClass}`}>
                          Parent / Relative Last Name:{' '}
                        </span>
                        {referenceDetails.refLastName}
                      </Typography>
                      <Typography className={`${dofClassName.marginTop8px}`}>
                        <span className={`${dofClassName.spanClass}`}>
                          {' '}
                          Phone #:
                        </span>
                        {referenceDetails.refPhoneNumber}
                      </Typography>

                      {referenceDetails.refBestTimeToCall ? (
                        <Typography className={`${dofClassName.marginTop8px}`}>
                          <span className={`${dofClassName.spanClass}`}>
                            Best Time to Call:{' '}
                          </span>
                          {findLabel(
                            bestTimeToCallOptions,
                            referenceDetails.refBestTimeToCall
                          )}
                        </Typography>
                      ) : (
                        ''
                      )}

                      <Typography className={`${dofClassName.marginTop8px}`}>
                        <span className={`${dofClassName.spanClass}`}>
                          {' '}
                          Relationship{' '}
                        </span>
                        {findLabel(
                          relationshipOptions,
                          referenceDetails.relationShipType
                        )}
                      </Typography>

                      {referenceDetails.refAddress1 ? (
                        <Typography className={`${dofClassName.marginTop8px}`}>
                          <span className={`${dofClassName.spanClass}`}>
                            {' '}
                            Address Line 1:{' '}
                          </span>
                          {referenceDetails.refAddress1}
                        </Typography>
                      ) : (
                        ''
                      )}

                      {referenceDetails.refAddress2 ? (
                        <Typography className={`${dofClassName.marginTop8px}`}>
                          <span className={`${dofClassName.spanClass}`}>
                            {' '}
                            Address Line 2:{' '}
                          </span>
                          {referenceDetails.refAddress2}
                        </Typography>
                      ) : (
                        ''
                      )}

                      {referenceDetails.refCity ? (
                        <Typography className={`${dofClassName.marginTop8px}`}>
                          <span className={`${dofClassName.spanClass}`}>
                            {' '}
                            City:{' '}
                          </span>
                          {referenceDetails.refCity}
                        </Typography>
                      ) : (
                        ''
                      )}

                      {referenceDetails.refState ? (
                        <Typography className={`${dofClassName.marginTop8px}`}>
                          <span className={`${dofClassName.spanClass}`}>
                            {' '}
                            State:{' '}
                          </span>
                          {referenceDetails.refState}
                        </Typography>
                      ) : (
                        ''
                      )}

                      {referenceDetails.refZipCode ? (
                        <Typography className={`${dofClassName.marginTop8px}`}>
                          <span className={`${dofClassName.spanClass}`}>
                            {' '}
                            ZIP Code:{' '}
                          </span>
                          {referenceDetails.refZipCode}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid> : null}

                  </> : null}
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.floatCenter}`}
          >
            <Grid container lg={8} md={8} sm={10} xs={11}>
              <Grid item lg={1} className={dofClassName.flexBasis}>
                <RACCheckBox
                  value={verifyBox1}
                  checked={verifyBox1}
                  name="check_box_1"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item lg={11}>
                <Typography className={dofClassName.textAlignJustify}>
                  I certify that the information I have provided in this rental
                  order form is correct, that I am not aware of any facts that
                  could lead to my filing for bankruptcy and that I have not
                  consulted with any attorney regarding bankruptcy in the past
                  90 days. I understand that Rent-A-Center will not fulfill my
                  rental order if any of the information I provide is
                  inaccurate. I authorize Rent-A-Center to contact any person or
                  business (including employers and landlords) I have provided
                  on this rental order form to verify the information or to
                  obtain location information about me throughout the duration
                  of my agreement. This authorization ends when a person or
                  business asks Rent-A-Center not to contact them or I make the
                  request in writing to Rent-A-Center.
                </Typography>
                <Typography
                  className={`${dofClassName.textAlignJustify} ${dofClassName.marginTop8px}`}
                >
                  You understand that by clicking on the "Continue"
                  button immediately following this notice, you are providing
                  written instructions to Rent-A-Center under the Fair Credit
                  Reporting Act authorizing Rent-A-Center to obtain information
                  about you from credit reporting agencies (including but not
                  limited to Experian, Clarity, Equifax, and TransUnion) or
                  others, including your creditors and other third parties, (1)
                  in connection with this transaction for the purpose of
                  evaluating your rental purchase application, to confirm your
                  identity, and to avoid fraudulent transactions, and (2) at any
                  time and use that information for the purposes of (a)
                  considering you for new offers, programs, and services that
                  Rent-A-Center, its affiliates, or third parties may offer in
                  the future, and (b) for marketing such programs to you.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.floatCenter} ${dofClassName.btnDivMargin}`}
          >
            <Grid
              container
              lg={8}
              md={8}
              sm={10}
              xs={11}
              className={`${dofClassName.spaceBetween}`}
            >
              <Grid
                item
                lg={3}
                md={3}
                sm={4}
                xs={4}
                className={`${dofClassName.textAlignLeft}`}
              >
                <RACButton
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    handleExisCustPreviousBtn();
                  }}
                >
                  Previous
                </RACButton>
              </Grid>
              <Grid item className={`${dofClassName.textAlignRight}}`}>
                <RACButton
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleContinueBtn();
                  }}
                  // disabled={customerDetails?.approvalResponse == '1' ? !verifyBox1 : !(verifyBox1 && verifyBox2)}
                  disabled={verifyBox1 ? false : true}
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LoaderPopup message={'Please wait...'} />
      )}
    </Grid>
  );
}
