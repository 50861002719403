/* eslint-disable */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';


export const DofCssClasses = () => {
  const useClasses = makeStyles((theme) => ({


    [theme.breakpoints.between(375, 450)]: {
      fontSizeXSM: {
        fontSize: '11px'
      }

    },

    smartWizardList: {
      position: 'relative',
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
      width: 'auto',
      // '&:after': {
      //   borderBottom: '2px solid #2179FE',
      //   content: '""',
      //   position: 'absolute',
      //   right: '-225%',
      //   width: '252%',
      //   top: '29%',
      // },
      // '&:last-child': {
      //   '&:after': {
      //     border: 'none',
      //   },
      // },
    },

    // yyyy



    forfont: {
      fontFamily: 'OpenSans-bold !important',
      fontSize: "20px !important"
    },

    forspace: {
      width: '50%', justifyContent: 'center'
    },

    forjustify: {
      justifyContent: 'center !important',
      marginTop: '120px !important'
    },
    foralign: {
      marginTop: '20px'
    },

    //userInfo
    // flexBasis: {
    //   flexBasis: '0',
    // },
    // textAlignJustify: {
    //   textAlign: 'justify'
    // },

    // Approval Page

    forweeklyapproval: {
      fontSize: '17px !important',

      marginTop: '10px'
    },

    totalapprovalamount: {
      color: '#0dcaf0', marginTop: '10px', fontSize: '30px', fontFamily: 'OpenSans-bold !important'
    },

    topmargin: {
      marginTop: '30px'
    },


    labelmargintop: {
      marginTop: ' 15px',
      fontFamily: 'OpenSans-regular',
      '& p': {
        textAlign: 'left'
      }
    },

    forfontsize: {
      fontSize: '20px '
    },

    forblue: {
      color: '#2179FE'
    },
    opensansbold: {
      fontFamily: 'OpenSans-bold'

    },
    forheight: {
      height: '30 px !important'
    },
    bodycontainer: {
      // margin: ' 0% 23%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
      marginTop: '7%',
      '& input': {
        height: '30px'
      }
    },
    forheading: {
      fontWeight: 'bold', fontSize: "20px",
      fontFamily: 'OpenSans-bold'
    },
    // Dof Headers Classes 
    parentClass: {
      height: '65px',
      backgroundColor: '#2179FE'
    },


    fontWeight: {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(13),
    },
    appClass: {
      width: '100%',
      border: 'aliceblue',
      borderRadius: theme.typography.pxToRem(3),
      backgroundColor: 'aliceblue',
      overflow: 'hidden'
    },

    existCustTitle: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 'bold',
      textAlign: 'center'
    },

    fontSize23px: {
      fontSize: theme.typography.pxToRem(23)
    },
    topMargin: {
      marginTop: '40px'
    },

    floatCenter: {
      justifyContent: 'center !important',
      margin: '10px 0'
    },
    floatLeft: {
      justifyContent: 'left !important'
    },

    floatRight: {
      justifyContent: 'right !important'
    },

    displayFlex: {
      display: 'flex',
    },

    spaceBetween: {
      // display: 'flex',
      justifyContent: 'space-between',
      // alignItems: 'space-between'
    },
    marginLeftRight: {
      padding: '0 8px'
    },
    alignCenter: {
      textAlign: 'center',
    },
    cardPadding: {
      padding: '8% !important'
    }
    ,
    textBold: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: 'OpenSans-bold',
      color: '#000'
    },
    cardContainer: {
      borderRadius: '1rem',
      boxShadow: '0px 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
      height: 'auto',
      padding: '3%',
      marginTop: '35px',
    },

    cardMargin: {
      marginTop: '23px !important'
    },

    marginTop8px: {
      marginTop: '8px'
    },

    marginTop16px: {
      marginTop: '16px'
    },

    titleMargin: {
      marginTop: '15px',
      marginBottom: '5px',
    },

    marginBottom: {
      marginBottom: '18px'
    },

    marginBottomforInput: {
      marginBottom: '8px !important',
      fontWeight: '400 !important',
      fontFamily: 'OpenSans-semibold !important',
      fontSize: '16px !important',
    },

    marginBottomforInput1: {
      marginBottom: '8px !important',
      fontWeight: '400 !important',
      fontFamily: 'OpenSans-semibold !important',
      fontSize: '16px !important',
      textAlign: 'left'
    },

    inputBoxHeight: {
      "& input": {
        height: '25px !important'
      }
    },

    inputBoxHeightForSelect: {
      "& div": {
        "& div": {
          "& div": {
            height: '20px !important',
            paddingTop: '10px',
            textAlign: 'left'
          }
        }
      }
    },

    datePickerClass: {
      "& div": {
        "& label": {
          marginBottom: '8px !important',
          fontWeight: '400 !important',
          fontFamily: 'OpenSans-semibold !important',
          fontSize: '16px !important'
        }
      }
    },


    fontWeightForName: {
      fontFamily: 'OpenSans-bold !important',
      fontSize: theme.typography.pxToRem(25),
      fontWeight: 'bold !important'
    },

    existCustHead: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(18),
      marginTop: '30px'
    },

    existCustHeadPayfone: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(18),
      marginTop: '30px'
    },

    existCustTexts: {
      fontWeight: '900',
      fontSize: theme.typography.pxToRem(15),
      fontFamily: 'OpenSans-bold'
    },

    textAlignLeft: {
      textAlign: 'left'
    },

    textAlignCenter: {
      textAlign: 'center'
    },

    textAlignRight: {
      textAlign: 'right'
    },
    textAlignRightForAmount: {
      "& input": {
        textAlign: 'right'
      }
    },

    marginInputFields: {
      margin: '4px 0'
    },

    // For Create Customer CSS
    topMarginCreateCustomer: {
      marginTop: '65px'
    },
    dateFontSize: {
      fontSize: '13.5px',
    },

    btnDivMargin: {
      marginTop: '27px'
    },

    dollarClass: {
      paddingTop: "12.4px",
      paddingBottom: "7.5px",
      height: '40px !important',

    },

    headingForReview: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 'bold',
      fontFamily: 'OpenSans-bold',
      margin: '15px 0'
    },

    spanClass: {
      fontFamily: 'OpenSans-bold',
      fontWeight: '600 !important',
    },

    marginLeft: {
      marginLeft: '10px'
    },

    textAlignJustify: {
      textAlign: 'justify'
    },
    flexBasis: {
      flexBasis: '0'
    },

    colorForReviewPage: {
      backgroundColor: '#ECF0F3 !important'
    },
    whiteColor: {
      backgroundColor: 'white !important'
    },

    blueColor: {
      color: '#2179FE !important'
    },

    textAlignRightforAmount: {
      '& input': {
        textAlign: 'right',
      },
    },


    // Wizard Styles 

    smartWizard: {
      overflow: 'hidden',
      justifyContent: 'space-between',
      position: 'relative',
      '&:after': {
        borderBottom: '2px solid #2179FE',
        content: '""',
        position: 'absolute',
        left: '30px',
        right: '30px',
        top: '20px',
        zIndex: '-1',
      },

    },

    flexBasis0: {
      flexBasis: '0% !important'
    },






    borderDone: {
      border: '3px solid #2179FE',
      borderColor: "#2179FE",
      borderRadius: '30px',
      color: `${RACCOLOR.WHITE}`,
    },


    paddingTop3: {
      paddingTop: '3px',
    },

    done: {
      // backgroundImage: `url(${completedIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: '#2179FE',
      color: 'transparent',
      borderColor: '#2179FE',
    },

    smartWizardListLinkActive: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(14),
      width: 'auto',
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(12),
      textDecoration: 'none',
    },

    basicInfoCardStyle: {
      backgroundColor: '#F5FAFD',
      padding: '4%',
      borderRadius: '2%',
      textAlign: 'left'
    },

    textHeading: {
      fontFamily: 'OpenSans-bold',
      color: '#2468FF',
      fontSize: '16px',
      marginTop: '30px'
    },
    mt40: {
      marginTop: '40px'
    },

    subHeading: {
      fontSize: '16px',
      fontFamily: 'OpenSans-bold'
    },
    inputContainerStyle: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: '100%',
      // maxWidth: '200px',
      cursor: 'pointer',
      textAlign: 'left',
      backgroundColor: 'white',
      display: 'inline-block',
      marginTop: '12px'
    },

    datePickerWrapperStyle: {
      position: 'relative',
      zIndex: 1
    },
    calendarStyle: {
      position: 'absolute',
      top: 'calc(100% + 4px)',
      left: '0',
      zIndex: 1,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    labelStyle: {
      display: 'block',
      fontWeight: 'bold',
      marginTop: '3px',
      fontSize: '15px',
      textAlign: 'left'
    },
    tcpaselection: {
      marginTop: '25px',
      zIndex: 1,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },


  }))
  return useClasses()
}